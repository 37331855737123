import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { TitleColor, FontSizeResponsive } from '../../_Styles';
import useWindowSize from '../../../hooks/useWindowsSize';

const TitleSection = ({ title, styles }) => {
  const { width } = useWindowSize();
  return (
    <Fragment>
      <Typography.Title
        level={2}
        style={{
          textAlign: 'center',
          color: `${TitleColor}`,
          fontSize: FontSizeResponsive(width, `2rem`, `3rem`, 600),
          textTransform: 'capitalize',
          fontWeight: 800,
          transition: 'all 300ms ease-in-out',
          marginBottom: 0,
          fontFamily: 'Bebas Neue',
          ...styles,
        }}
      >
        {title}
      </Typography.Title>
    </Fragment>
  );
};

export default TitleSection;
