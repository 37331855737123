import React, { Fragment } from 'react';
import Section from '../Section/Section';
import { Row, Col } from 'antd';
import FormContact from '../../FormContact/Form';

const Contacto = ({ title, subtitle, from }) => {
  return (
    <Fragment>
      <Section
        name="contacto"
        title={title}
        subtitle={subtitle}
      >
        <Row typeof="flex" justify="center">
          <Col xs={24} sm={18} md={12} lg={8}>
            <FormContact from={from}></FormContact>
          </Col>
        </Row>
      </Section>
    </Fragment>
  );
};

export default Contacto;
