const firebaseConfig = {
  apiKey: 'AIzaSyD1D17f_3IhJ8s0pCqgm0TUqUMp7bQOChQ',
  authDomain: 'coachingacademico-b6048.firebaseapp.com',
  databaseURL: 'https://coachingacademico-b6048.firebaseio.com',
  projectId: 'coachingacademico-b6048',
  storageBucket: 'coachingacademico-b6048.appspot.com',
  messagingSenderId: '751316098130',
  appId: '1:751316098130:web:e64fd60172e21f726b138b',
  measurementId: 'G-V5006LWDH2',
};

export default firebaseConfig;
