import styled from '@emotion/styled';
import { primary } from '../../_Styles';

const isBackground = (background, halfBackground) => {
  if (background && !halfBackground) {
    return background;
  } else {
    return `white`;
  }
};

const isColor = background => {
  if (background === '#222222' || background === 'white') {
    return 'white';
  } else {
    return `${primary}`;
  }
};

const isDivider = (Background, halfBackground, height) => {
  if (Background === `white ` && !halfBackground) {
    return `border-bottom: ${height}px solid 'white'`;
  } else if (Background !== `#222222` && !halfBackground) {
    return `border-bottom: ${height}px solid white`;
  } else {
    return `border-bottom: ${height}px solid ${primary}`;
  }
};

export const Divider = styled.div`
  display: block;
  margin: 0 auto;
  ${props => isDivider(props.background, props.halfBackground, 3)};
  width: 160px;
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  color: inherit;
  max-width: 600px;
`;

export const Title = styled.h2`
  margin-bottom: 0;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  margin-bottom: 5px;
  letter-spacing: 1px;
  color: inherit;
`;

export const SectionWrapper = styled.section`
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 1rem;
  background: ${props =>
    isBackground(props.background, props.halfBackground)};
  color: ${props => isColor(props.background)};
`;
