import React, { Fragment } from 'react';
import { Row, Col } from 'antd';

import styled from '@emotion/styled';
import { primary } from '../../_Styles';
import {
  Subtitle,
  SectionWrapper,
  Title,
  Divider,
} from '../../sections/Section/styles';

const HalfDarkBackground = styled.div`
  position: absolute;
  top: 0;
  background: ${primary};
  height: 60vh;
  width: 100%;
  h2 {
    color: white !important;
  }
`;

const Section = ({
  name,
  title,
  subtitle,
  children,
  background,
  halfBackground,
}) => {
  return (
    <Fragment>
      <SectionWrapper background={background} id={name}>
        {halfBackground && <HalfDarkBackground />}
        <Row typeof="flex" style={{ width: '100%' }} justify="center">
          <Col xs={24}>
            <Title background={background}>{title}</Title>
            <Divider
              background={background}
              halfBackground={true}
            ></Divider>
            <Subtitle background={background}>{subtitle}</Subtitle>
            {children}
          </Col>
        </Row>
      </SectionWrapper>
    </Fragment>
  );
};

export default Section;
