import React, { useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import { Button } from '../UIComponents/Button';
import { Form } from './_styles';
import { FirebaseContext } from '../../firebase';

const FormContact = ({ from }) => {
  // context con las operaciones CRUD

  const { firebase } = useContext(FirebaseContext);
  // console.log(FirebaseContext);
  // console.log(firebase);

  // React Form Hooks
  const { register, handleSubmit, errors, reset } = useForm({
    validateCriteriaMode: 'all',
    mode: 'onBlur',
  });
  const onSubmit = async (data) => {
    const message = { ...data, fecha: Date.now(), motivo: from };
    // insertar en base de datos
    firebase.db
      .collection('messages')
      .add(message)
      .then(() => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'MENSAJE ENVIADO',
          text: `${data.nombre} ,tu mensaje fue enviado correctamente a la brevedad nos pondremos en contacto para brindarte más información`,
          showConfirmButton: false,
          timer: 3500,
        });

        reset();
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'OPPPSS',
          text: `${data.nombre} ,hubo un error en el envío. Te proponemos que vuelvas a enviar tu mensaje.<br/> ${error}`,
          showConfirmButton: false,
          timer: 3500,
        });
      });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="nombre">
          Nombre
          <input
            id="nombre"
            className="form__input"
            type="text"
            placeholder="Nombre"
            defaultValue=""
            name="nombre"
            ref={register({
              required: 'El nombre es obligatorio',
              minLength: {
                value: 3,
                message:
                  'El nombre debe contener al menos 3 caracteres',
              },
            })}
          />
        </label>
        <span className="form__alert-error">
          {errors.nombre && errors.nombre.message}
        </span>
        {/* <span className="form__alert-error">{errors.nombre.type === 'minLength' && 'El campo minimo'}</span> */}
        <label htmlFor="apellido">
          Apellido
          <input
            id="apellido"
            className="form__input"
            type="text"
            placeholder="Apellido"
            defaultValue=""
            name="apellido"
            ref={register({
              required: 'El apellido es obligatorio',
              minLength: {
                value: 3,
                message:
                  'El apellido debe contener al menos 3 caracteres',
              },
            })}
          />
        </label>
        <span className="form__alert-error">
          {errors.apellido && errors.apellido.message}
        </span>
        <label htmlFor="whatsapp">
          Whatsapp
          <input
            id="whatsapp"
            className="form__input"
            type="number"
            placeholder="Ej: 115182456 (sin 0 ni el 15)"
            name="whatsapp"
            defaultValue=""
            ref={register({
              required: 'El número de whatsapp es obligatorio',
              minLength: {
                value: 9,
                message:
                  'El teléfono debe tener al menos 9 caracteres ',
              },
              pattern: {
                value:
                  '/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im/',
                message: 'Ingresa un número válido',
              },
            })}
          />
        </label>
        <span className="form__alert-error">
          {errors.whatsapp && errors.whatsapp.message}
        </span>
        <label htmlFor="mensaje">
          Mensaje
          <textarea
            id="mensaje"
            className="form__text-area"
            name="mensaje"
            defaultValue=""
            placeholder="¿Cómo podemos ayudarte?"
            ref={register({
              required: 'El mensaje es obligatorio',
              minLength: {
                value: 10,
                message:
                  'Tu consulta debe tener al menos 10 caracteres ',
              },
            })}
          />
        </label>
        <span className="form__alert-error">
          {errors.mensaje && errors.mensaje.message}
        </span>
        <Button type="submit" block={true}>
          Enviar
        </Button>
      </Form>
    </Fragment>
  );
};

export default FormContact;
