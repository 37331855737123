import React, { Fragment } from "react"
import { Row, Col } from "antd"

const Section = ({ id, children, style }) => {
  return (
    <Fragment>
      <section id={id}>
        <Row
          align="middle"
          justify="center"
          style={{ overflow: "hiden", ...style }}
        >
          <Col xs={24}>{children}</Col>
        </Row>
      </section>
    </Fragment>
  )
}

export default Section
