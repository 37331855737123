import React, { Fragment } from "react"

const MenuList = ({ children }) => {
  return (
    <Fragment>
      <div className="items_wrapper">
        <ul>{children}</ul>
      </div>
    </Fragment>
  )
}

export default MenuList
