import React, { Fragment, useEffect } from "react"

const MenuItem = ({ children, handleClick }) => {
  return (
    <Fragment>
      <li onClick={handleClick}>{children}</li>
    </Fragment>
  )
}

export default MenuItem
