import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { boxShadow } from '../_Styles';

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  transition: all 300ms ease-in-out;
  z-index: -1;
  background: white;
  &:hover {
    ${boxShadow};
    transform: scale(1.05);
    z-index: 999999;
    /* background: white; */
    /* color: white; */
    h3 {
      /* color: white; */
    }
  }
`;
const IconWrapper = styled.div`
  margin-bottom: 1rem;
`;
const TitleWrapper = styled.h3`
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.1rem;
`;

const Features = ({ icon, title, description }) => {
  return (
    <Fragment>
      <FeatureWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TitleWrapper>{title}</TitleWrapper>
        <p>{description}</p>
      </FeatureWrapper>
    </Fragment>
  );
};

export default Features;
