export const primary = '#222222';
export const gray = '#5A5A5A';
export const bodyColor = '#B1B1B1';
export const gray100 = '#F9F9F9';
export const gray200 = '#E5E5E5';
export const grayLinks = '#AFAFAF';
// Shradow

export const boxShadow = `box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);`;
export const FontSizeResponsive = (
    widthWindowSize,
    minFontSizeTitle,
    maxFontSizeTitle,
    minBreackpointInPX,
) =>
    widthWindowSize >= minBreackpointInPX
        ? maxFontSizeTitle
        : minFontSizeTitle;
export const TitleColor = '#9B6596';