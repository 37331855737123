import styled from '@emotion/styled';
import { primary } from '../_Styles';
export const Button = styled.button`
  background: ${primary};
  border: 2px solid ${primary};
  color: white;
  padding: 0.5rem 3rem;
  font-weight: 700;
  z-index: 1;
  width: ${(props) => (props.block ? '100%' : 'auto')};
  /* ${(props) => (props.block ? 'width:100%' : 'width:auto')} */
  &:hover {
    cursor: pointer;
    background: white;
    border: 2px solid ${primary};
    color: ${primary};
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    background: ${primary};
    color: white;
    border: 2px solid white;
    &:hover {
      background: white;
      color: ${primary};
      border: 2px solid white;
    }
  }
`;
