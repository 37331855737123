import React, { Fragment, useState } from 'react';

const IconFacebook = ({ color }) => {
  const initialState = color;
  const [colorHover, setColorHover] = useState(initialState);

  return (
    <Fragment>
      <svg
        onMouseOver={() => setColorHover('white')}
        onMouseLeave={() => setColorHover(initialState)}
        onFocus={() => setColorHover('white')}
        width="9"
        height="16"
        fill="currentColor"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.422 16V8.711h2.489l.356-2.844H5.422V4.09c0-.8.267-1.422 1.422-1.422h1.511V.09C8 .09 7.111 0 6.133 0a3.431 3.431 0 00-3.644 3.733v2.134H0v2.844h2.489v7.29h2.933z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath>
            <path fill="currentColor" d="M0 0h8.356v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Fragment>
  );
};

export default IconFacebook;
