import React, { Fragment, useState } from 'react';

export const IconInstagram = ({ color }) => {
  const initialState = color;
  const [colorHover, setColorHover] = useState(initialState);
  return (
    <Fragment>
      <svg
        width="16"
        height="16"
        fill="currentColor"
        onMouseOver={() => setColorHover('white')}
        onMouseLeave={() => setColorHover(initialState)}
        onFocus={() => setColorHover('white')}
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1.422a24.53 24.53 0 013.2.09 4.123 4.123 0 011.51.266c.667.31 1.202.845 1.512 1.511.183.483.273.995.267 1.511 0 .8.089 1.067.089 3.2.04 1.068.01 2.136-.09 3.2a4.121 4.121 0 01-.266 1.511c-.31.666-.845 1.2-1.511 1.511a4.125 4.125 0 01-1.511.267c-.8 0-1.067.09-3.2.09-1.068.04-2.136.01-3.2-.09a4.125 4.125 0 01-1.511-.267 3.122 3.122 0 01-1.511-1.51A4.124 4.124 0 011.51 11.2c0-.8-.09-1.067-.09-3.2a24.53 24.53 0 01.09-3.2 4.123 4.123 0 01.267-1.51c.155-.33.365-.631.622-.89a1.5 1.5 0 01.889-.622 4.123 4.123 0 011.51-.267A24.53 24.53 0 018 1.422zM8 0a26.264 26.264 0 00-3.29.09 5.488 5.488 0 00-1.954.354 3.48 3.48 0 00-1.422.89 3.48 3.48 0 00-.89 1.422 4.05 4.05 0 00-.355 1.955 26.264 26.264 0 00-.09 3.29 26.537 26.537 0 00.09 3.288c-.001.668.12 1.331.356 1.956a3.48 3.48 0 00.889 1.422 3.48 3.48 0 001.422.89 5.49 5.49 0 001.956.355C5.805 16.01 6.903 16.04 8 16c1.097.04 2.195.01 3.289-.089a5.489 5.489 0 001.956-.356 3.73 3.73 0 002.31-2.31 5.488 5.488 0 00.357-1.957C15.91 10.4 16 10.133 16 8a26.26 26.26 0 00-.09-3.289 5.487 5.487 0 00-.355-1.956 3.478 3.478 0 00-.89-1.422 3.48 3.48 0 00-1.421-.889A5.488 5.488 0 0011.289.09 26.263 26.263 0 007.999 0zm0 3.911a4.023 4.023 0 00-4.09 4.09A4.09 4.09 0 108 3.91zm0 6.756A2.62 2.62 0 015.333 8 2.619 2.619 0 018 5.333 2.619 2.619 0 0110.667 8 2.618 2.618 0 018 10.667zm4.267-7.91a.978.978 0 10.978.977.986.986 0 00-.978-.978z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath>
            <path fill="currentColor" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Fragment>
  );
};
