import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { primary, grayLinks } from '../../_Styles';

const FooterWrapper = styled.footer`
  /* display: flex;
	justify-content: start;
	align-items: center;
	width: 100vw;
    height: 200px; */
  padding: 2rem;
  background: ${primary};
  color: white;
`;
const Copy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  color: white;
  background: rgb(0, 0, 0);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 24px;
  .copy {
    font-family: 'Bebas Neue', cursive;
    font-size: 16px;
    margin-right: 5px;
  }
`;

export const TitleLinks = styled.h4`
  color: white;
  font-size: 1.2rem;
`;

export const ListLink = styled.ul`
  list-style: none;
`;

export const ListLinkItems = styled.li`
  color: ${grayLinks};
  margin-top: 8px;
  &:hover {
    cursor: pointer;
    color: white;
  }
`;

const Footer = ({ children }) => {
  const date = new Date().getFullYear();
  return (
    <Fragment>
      <FooterWrapper>{children}</FooterWrapper>
      <Copy>
        <span className="copy">
          {' '}
          &copy; Coaching Academico {date} with ❤ for the world
        </span>
      </Copy>
    </Fragment>
  );
};

export default Footer;
