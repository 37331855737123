import app from 'firebase/app';
import firebaseConfig from './config';
import 'firebase/auth';
import 'firebase/firestore';

class Firebase {
	constructor() {
		if (!app.apps.length) {
			app.initializeApp(firebaseConfig);
		}

		// Esta funcionalidad aún no se encuentra activa.
		// this.auth = app.auth();
		this.db = app.firestore();
	}
}

const firebase = new Firebase();

export default firebase;
