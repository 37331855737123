import { Col, Row } from 'antd';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-scroll';
import { IconSocialWrapper } from '../icons/_styles';
import { IconInstagram } from '../icons/IconInstagram';
import IconFacebook from '../icons/iconFacebook';
import Footer, {
  ListLink,
  ListLinkItems,
  TitleLinks,
} from '../sharded/Footer/Footer';
import { grayLinks } from '../_Styles';

const Layout = ({
  children,
  // allConfig,
  //   allImages
}) => {
  // const { socialNetwork } = allConfig.edges[0].node;
  // console.log(socialNetwork);

  return (
    <Fragment>
      <Helmet>
        <html lang="es-AR" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&family=Open+Sans:wght@400;600&display=swap"
          rel="stylesheet"
        ></link>
        <title>Coaching Académico</title>
        {/* <meta name="description" content={description} /> */}
        {/* <meta property="og:title" content={title} /> */}
        {/* <meta property="og:description" content={description} /> */}
        {/* <meta property="og:image" content={image} /> */}
        {/* <meta property="og:url" content={url} /> */}
      </Helmet>

      <main>{children}</main>
      <Footer>
        <Row
          type="flex"
          justify="start"
          align="top"
          gutter={[32, 16]}
        >
          <Col xs={24} sm={24} md={24}>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <img
                src="https://www.datocms-assets.com/24925/1590525321-logoca2.svg"
                width="250px"
                alt="Logo"
              />
              {/* <Logo color="white"></Logo> */}
            </Link>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <TitleLinks>Nosotros</TitleLinks>
            <p>
              Nos especializamos en brindar los mayores estándares de
              formación atendiendo personalmente tus motivaciones a
              fines de cumplir todas tus expectativas. Todo un equipo
              de profesionales formados en las mejores instituciones
              de renombre estamos comprometidos en acompañarte a cada
              paso de esta formación para verte alcanzar tu propio
              éxito.
            </p>
          </Col>
          <Col xs={12} sm={6} md={3} lg={3}>
            <TitleLinks>Enlaces</TitleLinks>
            <ListLink>
              <ListLinkItems key="0">
                <Link
                  activeClass="active"
                  to="metodologia"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Metodología
                </Link>
              </ListLinkItems>
              <ListLinkItems key="1">
                <Link
                  activeClass="active"
                  to="pedagogia"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Pedagogía
                </Link>
              </ListLinkItems>
              <ListLinkItems key="2">
                <Link
                  activeClass="active"
                  to="formacion"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Formación
                </Link>
              </ListLinkItems>
              <ListLinkItems key="3">
                <Link
                  activeClass="active"
                  to="capacitaciones"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Capacitaciones
                </Link>
              </ListLinkItems>
              <ListLinkItems key="4">
                <Link
                  activeClass="active"
                  to="testimonios"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Testimonios
                </Link>
              </ListLinkItems>
              <ListLinkItems key="5">
                <Link
                  activeClass="active"
                  to="contacto"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Contacto
                </Link>
              </ListLinkItems>
            </ListLink>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <TitleLinks>Redes Sociales</TitleLinks>
            <IconSocialWrapper>
              <a
                className="social-button"
                href="https://www.instagram.com/coaching_academico/"
                target="_blank"
                aria-label="Boton para acceder al Instagram de Coaching Académico"
                rel="noopener noreferrer"
              >
                <IconInstagram color={grayLinks}>
                  Instagram
                </IconInstagram>
              </a>
              <a
                className="social-button"
                href="https://www.facebook.com/coaching.academico2020"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Boton para acceder al Facebook de Coaching Académico"
              >
                <IconFacebook color={grayLinks} />
              </a>

              {/* <IconTwitter color={grayLinks} /> */}
            </IconSocialWrapper>
          </Col>
          <Col xs={24} sm={24} md={10} lg={12}>
            {/* <div className="box"></div> */}
          </Col>
        </Row>
      </Footer>
    </Fragment>
  );
};

export default Layout;
