import styled from '@emotion/styled';
import { boxShadow, gray200, primary } from '../_Styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 2rem;
  background: white;
  ${boxShadow};
  transition: all 400ms ease-in-out;
  input:-webkit-autofill,
	input:-webkit-autofill:hover,
	/* input:-webkit-autofill:focus, */
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
    transition: all 400ms ease-in-out;
    -webkit-text-fill-color: ${primary};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    border: 1px solid ${gray200};
    transition: background-color 5000s ease-in-out 0s;
  }
  & label {
    width: 100%;
    line-height: 16px;
    font-size: 14px;
  }

  .form {
    width: 100%;
    transition: all 400ms ease-in-out;
    &__input,
    &__text-area {
      margin-top: 5px;
      transition: all 400ms ease-in-out;
      width: 100%;
      padding: 0.8rem;
      border: 1px solid ${gray200};
      transition: all 400ms ease-in-out;
      color: ${primary};

      /* margin-bottom: 1rem; */
    }
    &__text-area {
      padding-top: 0.5rem;
      width: 100;
      min-height: 10rem;
      /* margin-bottom: 1rem; */
    }
    &__alert-error {
      color: red;
      text-transform: uppercase;
      width: 100%;
      padding: 0.5rem;
      font-size: 0.6rem;
      line-height: 1rem;
      height: 2rem;
    }
    &__input::placeholder,
    &__text-area::placeholder {
      color: ${gray200};
    }
    &__input:focus,
    &__text-area:focus {
      color: ${gray200};
      ${boxShadow}
      outline:none;
      border: 1px solid transparent;
      color: ${primary};
    }
  }
  input[type='submit'] {
    margin-top: 2rem;
  }
  /* label {
    margin: 0;
    padding: 0;
  } */
`;
