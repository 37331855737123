import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
// import { bodyColor } from '../../_Styles';

const SlideWrapper = styled.div`
  height: ${props => props.height};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 2rem;
  color: #333333;
`;

const Slide = ({ title, children, height }) => {
  const { Title } = Typography;
  return (
    <SlideWrapper height={height}>
      <Title level={3}>{title}</Title>
      {children}
    </SlideWrapper>
  );
};

export default Slide;
