import React, { Fragment } from 'react';
import { Carousel } from 'antd';

import styled from '@emotion/styled';

const WrapperSliders = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Sliders = ({ children }) => {
  // const { next, prev } = Carousel;
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Fragment>
      <WrapperSliders>
        <Carousel autoplay={true} {...settings}>
          {children}
        </Carousel>
      </WrapperSliders>
    </Fragment>
  );
};

export default Sliders;
