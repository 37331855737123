import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { gray, FontSizeResponsive } from '../../_Styles';
import useWindowSize from '../../../hooks/useWindowsSize';

const SubtitleSection = ({ subtitle, style }) => {
  const { width } = useWindowSize();
  return (
    <Fragment>
      <Typography.Paragraph
        strong
        style={{
          textAlign: 'center',
          color: `${gray}`,
          fontSize: FontSizeResponsive(width, `.9rem`, `1.2rem`, 600),
          ...style,
        }}
      >
        {subtitle}
      </Typography.Paragraph>
    </Fragment>
  );
};

export default SubtitleSection;
