import styled from '@emotion/styled';

export const IconSocialWrapper = styled.div`
display: flex;
align-items: center;
justify-content:flex-start;
svg{
   /* margin-right:1rem; */
   fill:'#AFAFAF';
      &:hover{
       cursor:pointer;
       fill:white;
   } 
}

}`;
